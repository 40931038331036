import React, { useEffect, useState } from "react";
import {
  deleteStockFromDb,
  editStockPortfolio,
  getCurrentDate,
} from "../../firebaseConfig/firestore";
import Swal from "sweetalert2";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import DotLoader from "../LoadingScreen/DotLoader";
import "./style.css";

const EditStock = ({
  onClose,
  userId,
  stockId,
  stock,
  setStock,
  refreshDetails,
}) => {
  const [formData, setFormData] = useState(stock);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [inputValue, setInputValue] = useState(stock.symbol);
  const stockApiKey = process.env.REACT_APP_STOCK_API_KEY;

  const fetchStockData = async () => {
    setIsLoading(true);
    setError("");

    const options = {
      method: "GET",
      url: "https://real-time-finance-data.p.rapidapi.com/stock-quote",
      params: { symbol: `${inputValue}:NASDAQ`, language: "en" },
      headers: {
        "X-RapidAPI-Key": stockApiKey, // Replace with your RapidAPI Key
        "X-RapidAPI-Host": "real-time-finance-data.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      const data = response.data;

      if (data.status === "OK" && data.data.price) {
        const price = parseFloat(data.data.price).toFixed(2);
        updateStateWithStockData(price, data.data.name);
      } else {
        setError("No data available for the specified symbol.");
        setTimeout(() => resetForm(), 5000);
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
      setTimeout(() => setError(""), 3000);
      setError("Error fetching stock details. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Simplified function to update the state based on latest stock data
  const updateStateWithStockData = (price, companyName) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      marketPrice: price,
      companyName: companyName,
    }));
  };

  useEffect(() => {
    recalculateDependentFields();
  }, [formData.type, formData.marketPrice, formData.shares, formData.tradePrice]);

  const recalculateDependentFields = () => {
    const { shares, tradePrice, marketPrice, type } = formData;
    const parsedShares = parseFloat(shares) || 0;
    const parsedTradePrice = parseFloat(tradePrice) || 0;
    const parsedMarketPrice = parseFloat(marketPrice) || 0;
  
    let tradeAmount, marketValue, profitLoss;
    tradeAmount = parsedShares * parsedTradePrice;
    marketValue = parsedShares * parsedMarketPrice;
  
    if (type === "Sell") {
      // When selling, calculate profit or loss
      profitLoss = (marketValue - tradeAmount) / tradeAmount * 100;
      console.log('sell',profitLoss, marketValue, tradeAmount);
    } else {
      // When buying, calculate total purchase price
      profitLoss = tradeAmount
      ? ((marketValue - tradeAmount) / tradeAmount) * 100
      : 0;
      console.log('buy',profitLoss, marketValue, tradeAmount);
    }
  
    setFormData((prevFormData) => ({
      ...prevFormData,
      tradeAmount: tradeAmount.toFixed(2),
      value: marketValue.toFixed(2),
      profitLoss: profitLoss.toFixed(2),
    }));
  };
  
  const handleChange = (e) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "tradePrice"; 
      value = e;
    }

    if (name === "symbol") {
      setInputValue(value);
      if (value) fetchStockData(value);
    } else {
      const newFormData = { ...formData, [name]: value };
      if (name === "shares" || name === "tradePrice") {
        recalculateDependentFields();
      }
    }
     if (name === "shares" && formData.type === "Sell") {
      const inputShares = parseFloat(value);
      if (inputShares > stock.shares) {
        setError("Cannot sell more shares than you own.");
        setTimeout(() => setError(""), 3000);
        return; 
      } else {
        setError(""); 
      }
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleUpdateStock = async () => {
    setIsLoading(true);
    try {
      await editStockPortfolio(userId, stockId, {
        ...formData,
        symbol: inputValue,
      });
      Swal.fire("Updated!", "Stock updated successfully.", "success");
      refreshDetails();
      onClose();
    } catch (error) {
      console.error("Error updating stock:", error);
      Swal.fire("Error!", "Error updating stock: " + error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteStock = async () => {
    setIsLoading(true);
    try {
      await deleteStockFromDb(userId.userId, stockId);
      // setStocks(stock.filter((stock) => stock.id !== stockId));
      Swal.fire("Deleted!", "Stock deleted successfully.", "success");
      refreshDetails();
      onClose();
    } catch (error) {
      console.error("Error deleting stock:", error);
      Swal.fire("Error!", "Error deleting stock: " + error.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Resets the form to initial state
  const resetForm = () => {
    setFormData({
      symbol: "",
      companyName: "",
      type: "Buy",
      shares: 0,
      tradeDate: getCurrentDate(),
      tradePrice: 0,
      marketPrice: 0,
      tradeAmount: 0,
      value: 0,
      profitLoss: 0,
      status: "Pending",
    });
    setInputValue("");
    setError("");
  };

  return (
    <div className="small-container" onClick={(e) => e.stopPropagation()}>
      <h2>Edit Stock</h2>
      <br />
      <div className="add_stock_modal">
        <label htmlFor="symbol"> Search for stock symbol:</label>
        <div className="search_wrapper">
          <input
            type="text"
            id="symbol"
            name="symbol"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            required
          />
          {isLoading && <DotLoader />}
          {error === "No data available for the specified symbol." && (
            <p className="error_msg">{error}</p>
          )}
          <button onClick={fetchStockData} className="search_btn">
            Search
          </button>
        </div>

        <label htmlFor="companyName">Company Name:</label>
        <input
          type="text"
          id="companyName"
          name="companyName"
          value={formData.companyName}
          onChange={handleChange}
          required
        />

        <label htmlFor="type">Type:</label>
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          required
        >
          <option value="Buy">Buy</option>
          <option value="Sell">Sell</option>
        </select>

        <label htmlFor="shares">Shares:</label>
        <input
          type="number"
          id="shares"
          name="shares"
          value={formData.shares}
          onChange={handleChange}
          required
        />

        <label htmlFor="tradeDate">Trade Date:</label>
        <input
          type="date"
          id="tradeDate"
          name="tradeDate"
          value={formData.tradeDate}
          onChange={handleChange}
          required
        />

        <label htmlFor="tradePrice">Trade Price:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="tradePrice"
          placeholder="$0.00"
          value={formData.tradePrice}
          decimalsLimit={2}
          onValueChange={(value) => handleChange(value, "tradePrice")}
          required
        />

        <label htmlFor="marketPrice">Market Price:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="marketPrice"
          placeholder="$0.00"
          value={formData.marketPrice}
          decimalsLimit={2}
          onValueChange={(value) => handleChange(value, "marketPrice")}
          readOnly
          required
        />

        <label htmlFor="tradeAmount">Trade Amount:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="tradeAmount"
          placeholder="$0.00"
          value={formData.tradeAmount}
          decimalsLimit={2}
          onValueChange={(value) => handleChange(value, "tradeAmount")}
          required
          readOnly
        />

        <label htmlFor="value">Value:</label>
        <CurrencyInput
          decimalSeparator="."
          prefix="$"
          name="value"
          placeholder="$0.00"
          value={formData.value}
          decimalsLimit={2}
          onValueChange={(value) => handleChange(value, "value")}
          required
          readOnly
        />

        <label htmlFor="profitLoss">Profit/ Loss (%):</label>
        <input
          type="text"
          id="profitLoss"
          name="profitLoss"
          value={formData.profitLoss}
          onChange={handleChange}
          readOnly
        />

        <label htmlFor="status">Status:</label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
          required
        >
          <option value="Pending">Pending</option>
          <option value="Outstanding">Outstanding</option>
          <option value="Paid">Paid</option>
          <option value="Sold">Sold</option>
        </select>

        <br />

        {error && <p className="error_msg">{error}</p>}

        <div className="buttons_wrap">
          <button onClick={handleUpdateStock} className="submit_btn">
            Submit
          </button>

          {isLoading && (
            <div className="spinner" style={{ margin: "0 auto" }}></div>
          )}

          <button onClick={handleDeleteStock} className="delete_btn">
            Delete Stock
          </button>

          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditStock;
